<template>
  <div>
    <el-breadcrumb separator-icon="ArrowRight" style="margin: 16px">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>订单管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card style="margin: 15px; min-height: calc(100vh - 111px)">
      <div>
        <!--    功能区-->
        <div style="margin: 10px 0">
          <!--    搜索区-->
          <div style="margin: 10px 0">
            <el-input v-model="search" clearable placeholder="请输入（订单号/客户id）" prefix-icon="Search" style="width: 20%"/>
            <el-button icon="Search" style="margin-left: 5px" type="primary" @click="loadid"></el-button>
            <el-button icon="refresh-left" style="margin-left: 10px" type="default" @click="loadid"></el-button>
<!--            <div style="float: right">-->
<!--              <el-tooltip content="添加" placement="top">-->
<!--                <el-button icon="plus" style="width: 50px" type="primary" @click="add"></el-button>-->
<!--              </el-tooltip>-->
<!--            </div>-->
          </div>
        </div>
        <!--    表格-->
        <el-table v-loading="loading" :data="tableData" border max-height="705" show-overflow-tooltip
                  style="width: 100%">
          <el-table-column label="#" type="index"/>
          <el-table-column label="用户名" prop="writers"/>

          <el-table-column label="客户id" prop="customerID"/>
          <el-table-column label="订单编号" prop="ordernumber" sortable width="300px"/>



          />


<!--          <el-table-column label="收款金额" prop="collection" sortable width="100px"/>-->
          <el-table-column label="订单类型" prop="serve" width="100px"
                           :filter-method="filterTag"
                           :filters="[
              { text: 'PPT', value: 'PPT' },
              { text: '文章', value: '文章' },
              { text: '平面设计', value: '平面设计' },
              { text: '数据分析', value: '数据分析' },
              { text: '问卷收集', value: '问卷收集' },
              { text: '问卷设计', value: '问卷设计' },
              { text: '程序设计', value: '程序设计' },

            ]"
                           filter-placement="bottom-end"
          />
          <el-table-column label="订单要求" prop="demand" width="100"/>
          <el-table-column label="派单客服" prop="customer" width="100"/>

          <el-table-column label="提成" prop="payment" sortable width="auto"/>
          <el-table-column label="完成状态"
                           width="120px"
                           :filter-method="filterTag"
                           :filters="[
              { text: '完成', value: '完成' },
              { text: '未完成', value: '未完成' },
            ]"
                           filter-placement="bottom-end"
                           prop="finish"
                           sortable
          >
            <template #default="scope">
              <el-tag
                  :type="scope.row.finish === '完成' ? 'success' : 'info'"
                  disable-transitions
              >{{ scope.row.finish }}
              </el-tag>
            </template>
          </el-table-column>
          <!--                           :filter-method="filterTag"-->
          <!--                           :filters="[-->
          <!--              { text: '已完成', value: '已完成' },-->
          <!--              { text: '未完成', value: '未完成' },-->
          <!--            ]"-->
          <!--                           filter-placement="bottom-end"-->
          <!--                           prop="finish"/>-->

          <el-table-column label="审核状态"
                           width="120px"
                           :filter-method="filterTag1"
                           :filters="[
              { text: '已审核', value: '已审核' },
              { text: '未审核', value: '未审核' },
            ]"
                           filter-placement="bottom-end"
                           prop="audit"
                           sortable
          >
            <template #default="scope">
              <el-tag
                  :type="scope.row.audit === '已审核' ? 'success' : 'info'"
                  disable-transitions
              >{{ scope.row.audit }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" prop="creationtime" sortable width="200px"/>
          <el-table-column label="最终确认时间" prop="deliverytime" sortable width="200px"/>
<!--          <el-table-column label="结算状态"-->
<!--                           width="81px"-->
<!--                           :filter-method="filterTag2"-->
<!--                           :filters="[-->
<!--              { text: '已结算', value: '已结算' },-->
<!--              { text: '未结算', value: '未结算' },-->
<!--            ]"-->
<!--                           filter-placement="bottom-end"-->
<!--                           prop="audit"-->
<!--                           sortable-->
<!--          >-->
<!--            <template #default="scope">-->
<!--              <el-tag-->
<!--                  :type="scope.row.settlement === '已结算' ? 'success' : 'info'"-->
<!--                  disable-transitions-->
<!--              >{{ scope.row.settlement }}-->
<!--              </el-tag>-->
<!--            </template>-->
<!--          </el-table-column>-->

<!--          <el-table-column label="奖惩金额" prop="incentive"/>-->
<!--          <el-table-column label="收款渠道"-->
<!--                           :filter-method="filterTag"-->
<!--                           :filters="[-->
<!--              { text: '淘宝', value: '淘宝' },-->
<!--              { text: '京东', value: '京东' },-->
<!--              { text: '支付宝', value: '支付宝' },-->
<!--              { text: '微信', value: '微信' },-->
<!--            ]"-->
<!--                           filter-placement="bottom-end"-->
<!--                           prop="manner"/>-->

<!--          <el-table-column label="备注" prop="remark"/>-->
          <!--      操作栏-->
          <el-table-column label="操作" width="100px" fixed="right">
            <template #default="scope">
<!--              <el-button v-if="scope.row.finish==='已完成' ||scope.row.finish==='未完成'" icon="more-filled" type="default" @click="showDetail(scope.row)"></el-button>-->
              <el-button v-if="scope.row.finish!=='已完成' " icon="Edit" type="primary" @click="handleEdit(scope.row)"
              ></el-button>
<!--              <el-popconfirm title="确认删除？" @confirm="handleDelete(scope.row.id)">-->
<!--                <template #reference>-->
<!--                  <el-button icon="Delete" type="danger"></el-button>-->
<!--                </template>-->
<!--              </el-popconfirm>-->
            </template>
          </el-table-column>
        </el-table>

        <!--分页-->
        <div style="margin: 10px 0">
          <el-pagination
              v-model:currentPage="currentPage"
              :page-size="pageSize"
              :page-sizes="[10, 20]"
              :total="total"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
        <!--        详情-->
        <el-dialog v-model="detailDialog" title="详情" width="50%">
          <el-card>
            <div v-html="detail.finish"></div>
          </el-card>
          <template #footer>
              <span class="dialog-footer">
                <el-button type="primary" @click="closeDetailDialog">确 定</el-button>
              </span>
          </template>
        </el-dialog>
        <!--      弹窗-->
        <div>
          <el-dialog v-model="dialogVisible" title="操作" width="30%" @close="cancel">
            <el-form ref="form" :model="form" :rules="rules" label-width="120px">
<!--              <el-form-item label="订单编号" prop="ordernumber"  >-->
<!--                <el-input v-model.number="form.ordernumber"  readonly  style="width: 80%" ></el-input>-->
<!--              </el-form-item>-->
<!--              <el-form-item label="订单类型" prop="serve">-->
<!--                <el-radio v-model="form.serve" label="PPT">PPT</el-radio>-->
<!--                <el-radio v-model="form.serve" label="文章">文章</el-radio>-->
<!--                <el-radio v-model="form.serve" label="平面设计">平面设计</el-radio>-->
<!--                <el-radio v-model="form.serve" label="数据分析">数据分析</el-radio>-->
<!--                <el-radio v-model="form.serve" label="问卷收集">问卷收集</el-radio>-->
<!--                <el-radio v-model="form.serve" label="问卷设计">问卷设计</el-radio>-->
<!--                <el-radio v-model="form.serve" label="程序设计">程序设计</el-radio>-->
<!--              </el-form-item>-->
<!--              <el-form-item label="创建时间" prop="creationtime" :disabled="disabled">-->
<!--                <el-date-picker-->
<!--                    v-model="form.creationtime"-->
<!--                    clearable-->
<!--                    readonly-->
<!--                    placeholder="选择时间"-->
<!--                    type="datetime"-->
<!--                    value-format="YYYY-MM-DD HH:mm:ss"-->
<!--                ></el-date-picker>-->
<!--              </el-form-item>-->
<!--              <el-form-item label="客户id" prop="customerID">-->
<!--                <el-input v-model="form.customerID" style="width: 80%" placeholder="请输入客户ID"></el-input>-->
<!--              </el-form-item>-->
<!--              <el-form-item label="收款金额" prop="collection">-->
<!--                <el-input v-model="form.collection" style="width: 80%"></el-input>-->
<!--              </el-form-item>-->
              <el-form-item label="订单要求" prop="demand" >
                <el-input v-model="form.demand" style="width: 80%" readonly placeholder="例:10页标准PPT/1000字/海报"></el-input>
              </el-form-item>
              <el-form-item label="派单客服" prop="customer">
                <el-input v-model="form.customer" style="width: 80%" readonly></el-input>
              </el-form-item>
<!--              <el-form-item label="写手" prop="writers">-->
<!--                <el-input v-model="form.writers" style="width: 80%"></el-input>-->
<!--              </el-form-item>-->
              <el-form-item label="提成" prop="payment">
                <el-input v-model="form.payment" style="width: 80%" readonly></el-input>
              </el-form-item>
              <el-form-item label="完成状态" prop="finish" style="margin-top: 27px">

                <el-radio v-model="form.finish" label="已完成" >已完成</el-radio>
                <el-radio v-model="form.finish" label="未完成">未完成</el-radio>
              </el-form-item>
<!--              <el-form-item label="最终确认时间" prop="deliverytime" >-->
<!--                <el-date-picker-->
<!--                    v-model="form.deliverytime"-->
<!--                    clearable-->
<!--                    readonly-->
<!--                    placeholder="选择时间"-->
<!--                    type="datetime"-->
<!--                    value-format="YYYY-MM-DD HH:mm:ss"-->
<!--                ></el-date-picker>-->
<!--              </el-form-item>-->
<!--              <el-form-item label="审核状态" prop="audit">-->
<!--                <el-radio v-model="form.audit" label="已审核" :disabled="form.finish !== '已完成'">已审核</el-radio>-->
<!--                <el-radio v-model="form.audit" label="未审核">未审核</el-radio>-->
<!--              </el-form-item>-->
<!--              <el-form-item label="结算状态" prop="settlement">-->
<!--                <el-radio v-model="form.settlement" label="已结算" :disabled="form.audit !== '已审核'">已结算</el-radio>-->
<!--                <el-radio v-model="form.settlement" label="未结算">未结算</el-radio>-->
<!--              </el-form-item>-->
<!--              <el-form-item label="奖惩金额" prop="incentive">-->
<!--                <el-input v-model="form.incentive" style="width: 80%"></el-input>-->
<!--              </el-form-item>-->
<!--              <el-form-item label="收款渠道" prop="manner">-->
<!--                <el-radio v-model="form.manner" label="淘宝">淘宝</el-radio>-->
<!--                <el-radio v-model="form.manner" label="京东">京东</el-radio>-->
<!--                <el-radio v-model="form.manner" label="微信">微信</el-radio>-->
<!--                <el-radio v-model="form.manner" label="支付宝">支付宝</el-radio>-->
<!--              </el-form-item>-->
<!--              <el-form-item label="备注" prop="remark">-->
<!--                <el-input-->
<!--                    v-model="form.remark"-->
<!--                    :autosize="{ minRows: 2, maxRows: 4 }"-->
<!--                    autosize-->
<!--                    style="width: 80%"-->
<!--                    type="textarea"-->
<!--                ></el-input>-->
<!--              </el-form-item>-->
            </el-form>
            <template #footer>
              <span class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
              </span>
            </template>
          </el-dialog>
        </div>
      </div>
    </el-card>
  </div>
</template>
<script src="@/assets/js/MyRoomInfo.js"></script>